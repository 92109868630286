import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { Observable } from 'rxjs';
import environment from 'src/environments/environment';
import { ShippingResponse } from 'src/app/modules/cart/models/cart.model';

@Injectable({
  providedIn: 'root',
})
export class VendorService {
  constructor(private api: ApiService) {}
  baseUrl = '/Vendor/';

  getVendor(vendorId: string): Observable<any> {
    return this.api.get<any>(
      `${this.baseUrl}Get/ById/ByVendor?vendorId=${vendorId}&region=${environment.stripe.country}`
      //`/Marketplace/Vendor/ById/ByVendor/Get?vendorId=${vendorId}&region=${environment.stripe.country}`
    );
  }

  getVendorByNodeId(nodeId: string): Observable<any> {
    const body = {};
    return this.api.post<any>(
      // `/Fabric/PIM/Vendor?Id=${nodeId}&region=${environment.stripe.country}`,
      `${this.baseUrl}Details?Id=${nodeId}&region=${environment.stripe.country}`,
      body
    );
  }

  postVendorSubmit(payload: any): Observable<any> {
    return this.api.post<any>(`${this.baseUrl}Enquiry/Create`, payload);
  }

  getShippingMethods(vendorId: string) {
    return this.api.get<ShippingResponse>(
      `${this.baseUrl}Get/Shipping/Methods?vendorId=${vendorId}&region=${environment.stripe.country}`
      //`/Fabric/OMS/Shipping/Method/ByVendor/Get?vendorId=${vendorId}&region=${environment.stripe.country}`,
    );
  }
}
